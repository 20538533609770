<template>
    <v-layout row class="pa-3 mb-2">
        <v-flex xs12 sm6 offset-sm3>
            <v-card flat>
                <v-card-title class="justify-center subheading pa-0"><b>{{ $t("pendingRelease.title")}}</b></v-card-title>
                <v-layout row wrap class="mb-5">
                    <v-flex xs12 v-for="box_id in boxIds" :key="box_id">
                        <v-list subheader>
                            <v-subheader>{{ packages.find((a) => a.box_id === box_id).box_name }}({{ box_id }})</v-subheader>
                            <v-list-tile v-for="pdata in packages.filter((a) => a.box_id === box_id)" :key="pdata.track_no">
                                <v-list-tile-action>
                                    <v-checkbox color="indigo" v-model="pdata.selected"></v-checkbox>
                                </v-list-tile-action>

                                <v-list-tile-content>
                                    <v-list-tile-title>{{ $t("pendingRelease.cell") }} {{ pdata.door_id }}</v-list-tile-title>
                                    <v-list-tile-sub-title>{{ $t("pendingRelease.putin-time") }}: {{ pdata.putin_time || "" }}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-btn
                            large
                            block
                            class="ma-1 white--text"
                            color="purple darken-2"
                            @click="confirmPending"
                            :disabled="packages.filter((a) => a.selected).length === 0"
                            >{{ $t("pendingRelease.confirm") }}</v-btn
                        >
                    </v-flex>
                    <v-flex xs12>
                        <v-btn large block class="ma-1 white--text" color="black darken-2" @click="leave">{{ $t("putIn.close") }}</v-btn>
                    </v-flex>
                </v-layout>

                <v-dialog persistent v-model="emptyDialog" width="250">
                    <v-card class="text-xs-center">
                        <v-card-text class="title">
                            {{ dialogMessage }}
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn block class="subheading" color="primary" @click="leave()">{{ $t("putIn.close") }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
                <message-dialog
                    :show.sync="msgDialog.show"
                    :msg="msgDialog.msg"
                    :primary-btn="msgDialog.primaryBtn"
                    :secondary-btn="msgDialog.secondaryBtn"
                    @primary="closeMsgDialogFunc"
                ></message-dialog>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios'
import store, { HostUrl } from "@/store"
import i18n from '@/i18n'
import { getAxiosConfig } from '@/utils/AuthService'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from "@/mixins/WaitDialogControl";
import MessageDialog from '@/components/MessageDialog'
import MessageDialogControl from '@/mixins/MessageDialogControl'

export default {
    name: 'LogisticsPendingRelease',
    components: { WaitingDialog, MessageDialog },
    mixins: [WaitDialogControl, MessageDialogControl],
    data() {
        return {
            titles: [i18n.t('pendingRelease.input-box-id'), i18n.t('pendingRelease.pending-list')],
            headers:[
                {
                    sortable: false,
                    width: "10px",
                },
                { text: i18n.t("pendingrecord.box-id"), value: "box_id" },
                { text: i18n.t("pendingrecord.box-name"), value: "box_name"},
                { text: i18n.t("pendingrecord.cell"), value: "door_id" },
                { text: i18n.t("pendingrecord.putin-time"), value: "putin_time" }
            ],
            closeMsgDialogFunc: () => {},
            emptyDialog: false,
            dialogMessage: "",
            packages:[]
        }
    },
    computed: {
        boxIds() {
            if (this.packages.length === 0) {
                return [];
            }
            const map = new Map();
            for (const item of this.packages) {
                if (!map.has(item.box_id)) {
                    map.set(item.box_id, true); // set any value to Map
                }
            }
            console.log(map.keys());
            return Array.from(map.keys());
        }
    },
    methods: {
        async getPendingRelease() {
            if (store.getters.user.id === 0) {
                return;
            }
            this.showDialog(i18n.t('pendingRelease.get-pending-list'), i18n.t('pendingRelease.fail-get-pending-list'))
            let self = this;
            try {
                var suburl = '/api/staff/pending/release'
                let response = await axios.get(HostUrl + suburl, getAxiosConfig())
                console.log(response);
                if (response.data.code === 0) {
                    const packages = response.data.packages;
                    let packageArray = [];
                    for (const track_no in packages) {
                        const { box_id, box_name, door_id, putin_time } = packages[track_no];
                        packageArray.push({
                            selected: false,
                            track_no,
                            box_id,
                            box_name,
                            door_id,
                            putin_time,
                        });
                    }
                    self.packages = packageArray;
                    self.closeDialog();
                } else {
                    store.commit('setSnackBar', {message: response.data.message, color: 'error'})
                }
            } catch (error) {
                store.commit('setSnackBar', {message: i18n.t('pendingRelease.server-error'), color: 'error'})
            } finally {
                self.closeDialog()
            }
        },
        confirmPending() {
            this.showDialog(i18n.t("pendingRelease.take-out"), i18n.t("pendingRelease.server-error"));
            this.sendPendingRelease()
        },
        async sendPendingRelease() {
            let self = this;
            let url = HostUrl + '/api/staff/pending/release';
            const packageList = self.packages.filter((a) => a.selected);
            if (packageList.length === 0) {
                store.commit("setSnackBar", {
                    message: i18n.t("takeoutchange.zero-package"),
                    color: "error",
                });
                return;
            }
            let payload = { track_nos: [] };
            packageList.forEach((pdata) => {
                payload.track_nos.push(pdata.track_no);
            });
            const updatePromise = axios
                .post(url, payload, getAxiosConfig())
                .then(function(response) {
                    if (response.data.code != 0) {
                        store.commit("setSnackBar", {
                            message: response.data.message,
                            color: "error",
                        });
                    }
                })
                .catch(function(error) {
                    console.error(error);
                    store.commit("setSnackBar", {
                        message: i18n.t("changePhone.update-info-fail"),
                        color: "error",
                    });
                });
            await Promise.all([updatePromise]).then(() => {
                self.closeDialog();
            });
            await self.getPendingRelease()
        },
        conti() {
            this.init()
        },
        leave() {
            this.$router.push(`/${i18n.locale}/logistics/menu`)
        }
    },
    activated() {
        this.getPendingRelease()
    }
};
</script>

<style scoped>
</style>
