<script>
export default {
    data() {
        return {
            msgDialog: {
                show: false,
                msg: '',
                primaryBtn: '關閉',
                secondaryBtn: null
            }
        }
    },
    methods: {
        showMsgDialog(msg, primaryBtn, secondaryBtn='') {
            this.msgDialog.msg = msg
            this.msgDialog.primaryBtn = primaryBtn
            this.msgDialog.secondaryBtn = secondaryBtn
            this.msgDialog.show = true
        },
        closeMsgDialog() {
            this.msgDialog.show = false
        }
    }
}
</script>
