<template>
    <v-dialog persistent v-model="show" width="250">
        <v-card class="text-xs-center">
            <v-card-text class="title"><span v-html="msg"></span>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn block flat v-if="secondaryBtn" class="subheading" color="primary" @click="clickSecondary">{{secondaryBtn}}</v-btn>
                <v-btn block v-if="primaryBtn" class="subheading" color="primary" @click="clickPrimary">{{primaryBtn}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'message-dialog',
    props: {
        show: {
            type: Boolean,
            required: true
        },
        msg: {
            type: String,
            required: true
        },
        primaryBtn: {
            type: String,
            default: '關閉'
        },
        secondaryBtn: {
            type: String,
            default: null
        }
    },
    methods: {
        clickSecondary() {
            this.$emit('update:show', false)
            this.$emit('secondary')
        },
        clickPrimary() {
            this.$emit('update:show', false)
            this.$emit('primary')
        }
    }
}
</script>

<style scoped>
</style>
